import { Col, Row, Card, Typography, Spin } from "antd";
import { useNavigate, useParams } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import DashbordSidebar from "../../components/DashboardSidebar";
import { Get } from "../../config/api/get";
import { FEEDBACK } from "../../config/constants/api";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
// const { Meta } = Card;
const { Title } = Typography;

const FeedbackDetails = () => {
  const token = useSelector((state) => state.user.userToken);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState(null);
  const getFeedbackDetails = () => {
    setLoading(true);
    Get(`${FEEDBACK.getFeedback}${id}`, token)
      .then((response) => {
        if (response.status) {
          setDetails(response?.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getFeedbackDetails();
  }, []);
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                      {!loading ? (
                        <div className="about-us-section">
                          <div className="bg-paren">
                            <Card className="details-card">
                              <Row
                                align={"middle"}
                                style={{
                                  marginBottom: "15px",
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Col lg={24} md={24} xs={24}>
                                  <div className="arrow-box2">
                                    <div>
                                      <FaArrowLeftLong
                                        className="arrow"
                                        onClick={() => navigate(-1)}
                                      />
                                    </div>
                                    <h3 className="heading-28">
                                      Feedback details
                                    </h3>
                                  </div>
                                </Col>
                              </Row>
                              {details && (
                                <Row align="middle" gutter={16}>
                                  <Col lg={14}>
                                    <p className="web-p">
                                      Name :{" "}
                                      <label>
                                      {details?.firstName + details?.lastName}
                                      </label>
                                    </p>
                                    <p className="web-p"> Email : <label>{details?.email}</label> </p>
                                    <p className="web-p">Subject : <label>{details?.subject}</label></p>
                                    <p className="web-p">Message : <label>{details?.message}</label></p>
                                  </Col>
                                </Row>
                              )}
                            </Card>
                          </div>
                        </div>
                      ) : (
                        <Col
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "150px 0px",
                          }}
                        >
                          <Spin style={{margin:'0px 500px'}}/>
                        </Col>
                      )}
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FeedbackDetails;
