import { Layout } from "antd";

import ProfileInfo from "../../views/profile/profileInfo";
function DropZone() {
  return (
    <Layout
      className=""
      style={{ backgroundColor: "transparent", minHeight: "100vh" }}
    >
      <ProfileInfo />
    </Layout>
  );
}

export default DropZone;
